<template>

  <div class="main">
    <img v-if="data.bgcImg" :src="data.bgcImg" style="width: 100%;display: block" alt="">
    <!--    <img src="../../assets/wx-share-4.jpg" style="width: 100%;display: block" alt="">-->

    <div class="see-more" :class="data.isGet ? 'getStyle' : ''" @click="toMyGet">
      {{ data.isGet ? '您已参与 查看奖励' : '参与活动 领取徽章'}}
    </div>


    <van-popup v-model:show="AnswerShow">
      <AnswerPopup
        ref="child"
        @closeAnswer="closeAnswer"
        @changIsShow="changIsShow"
        @rightAnswers="rightAnswers"
        @getGoods = "getGoods"
        class="popover"
      ></AnswerPopup>
    </van-popup>

    <!-- 暂不领取弹窗 -->
    <van-popup v-model:show="isShow">
      <div class="wrapper">
        <div class="prompt">
          <div>确认不领取湖南博物院数字纪念徽章吗</div>
          <div class="botton">
            <div @click="closeAnswer">暂不领取</div>
            <div @click="isShow = false" style="  color: rgba(0, 87, 31, 1);">继续答题</div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>

</template>

<script>
import {setStore, showFailToast, showSuccessToast, userAgentUa} from "../../../utils/util";
import {getCurrentInstance, reactive, ref} from "vue";
import AnswerPopup from "../../../components/answerPop-up/";

export default {
  name: "museumActivity",
  components:{
    AnswerPopup,
  },

  setup(){

    const { proxy } = getCurrentInstance();


    const AnswerShow = ref(false);
    const isShow = ref(false);


    const data = reactive({
      bgcImg:null,
      isGet:false
    })


    //获取背景图
    proxy.$http.get(process.env.VUE_APP_WW_BASE_URL + '/wenwu-pubapi-services/dict/detail?dict_key=museumActivityImg').then(res => {
      if (res.data.code == 200) {
        data.bgcImg =  res.data.data.dictValue;
      }
    })


    const closeAnswer = () => {
      AnswerShow.value = false;
      isShow.value = false
    }
    const changIsShow = () => {
      isShow.value = true
    }
    const rightAnswers = () => {
      AnswerShow.value = false;
    }
    const getGoods = () => {
      data.isGet = true;
    }

    const getActivityData = ()=>{

      proxy.$http.get(process.env.VUE_APP_WW_BASE_URL + '/wenwu-pubapi-services/dict/detail?dict_key=questionsBadge').then(res => {
        if (res.data.code == 200) {
          proxy.$http.get(process.env.VUE_APP_WW_BASE_URL + "/wenwu-order/order/showQuestionsBadge?productId=" + res.data.data.dictValue).then(respnse => {
            if (respnse.data.code == 6039) {
              data.isGet = true;
            } else {
              data.isGet = false;
            }
          })

        }
    })
      }



    const toMyGet = () => {

      if(data.isGet) {
           showSuccessToast('领取成功,请到个人中心-我的徽章中查看!');
        }else{
          AnswerShow.value = true;
        }
    }

    const  getUserToken =  async ()=>{

      let result;
      if(window.flutter_inappwebview.callHandler){

        result = await window.flutter_inappwebview.callHandler("getUserInfo");
      }else {
        result = await window.flutter_inappwebview.callHandler("getUserInfo", setTimeout(function () {

        }));
      }

      if (result != ''){//获取到了用户信息,直接弹窗

        setStore('wwToken',result.satoken);
        getActivityData();
      }

    }

        getUserToken();

    return {
      data,
      AnswerShow,
      isShow,
      toMyGet,
      closeAnswer,
      changIsShow,
      rightAnswers,
      getGoods


    }
  }
}
</script>

<style scoped lang="scss">

:deep(.van-popup) {
  width: 100%;
  height: 100%;
  background-color: transparent;
}


.popover{
  // width: 85%;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -60%);

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  img {
    display: block;
  }
}

.see-more {
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: calc(100% - 36px);
  background-color: #89B764;
  height: 50px;
  border-radius: 4px;
  bottom:calc(env(safe-area-inset-bottom) + 10px);
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  color: #fff;
}

.getStyle {
  color: #89B764;
  background-color: #DAF4C5;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.prompt {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.3rem;
  padding: 0.4rem;
  font-size: 13px;
  text-align: center;
  width: 55%;
}
.prompt .botton {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0 6% 0 6%;
}
.wrapper {
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
}

</style>

